exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-administration-deliveryrates-js": () => import("./../../../src/pages/administration/deliveryrates.js" /* webpackChunkName: "component---src-pages-administration-deliveryrates-js" */),
  "component---src-pages-administration-pickuppointedit-js": () => import("./../../../src/pages/administration/pickuppointedit.js" /* webpackChunkName: "component---src-pages-administration-pickuppointedit-js" */),
  "component---src-pages-administration-pickuppoints-js": () => import("./../../../src/pages/administration/pickuppoints.js" /* webpackChunkName: "component---src-pages-administration-pickuppoints-js" */),
  "component---src-pages-administration-seller-js": () => import("./../../../src/pages/administration/seller.js" /* webpackChunkName: "component---src-pages-administration-seller-js" */),
  "component---src-pages-administration-selleredit-js": () => import("./../../../src/pages/administration/selleredit.js" /* webpackChunkName: "component---src-pages-administration-selleredit-js" */),
  "component---src-pages-administration-serviceprovider-js": () => import("./../../../src/pages/administration/serviceprovider.js" /* webpackChunkName: "component---src-pages-administration-serviceprovider-js" */),
  "component---src-pages-administration-userlocation-js": () => import("./../../../src/pages/administration/userlocation.js" /* webpackChunkName: "component---src-pages-administration-userlocation-js" */),
  "component---src-pages-content-assets-js": () => import("./../../../src/pages/content/assets.js" /* webpackChunkName: "component---src-pages-content-assets-js" */),
  "component---src-pages-content-banner-js": () => import("./../../../src/pages/content/banner.js" /* webpackChunkName: "component---src-pages-content-banner-js" */),
  "component---src-pages-content-brandimage-js": () => import("./../../../src/pages/content/brandimage.js" /* webpackChunkName: "component---src-pages-content-brandimage-js" */),
  "component---src-pages-content-brandimageonly-js": () => import("./../../../src/pages/content/brandimageonly.js" /* webpackChunkName: "component---src-pages-content-brandimageonly-js" */),
  "component---src-pages-content-featureditems-js": () => import("./../../../src/pages/content/featureditems.js" /* webpackChunkName: "component---src-pages-content-featureditems-js" */),
  "component---src-pages-content-newitems-js": () => import("./../../../src/pages/content/newitems.js" /* webpackChunkName: "component---src-pages-content-newitems-js" */),
  "component---src-pages-content-promoitems-js": () => import("./../../../src/pages/content/promoitems.js" /* webpackChunkName: "component---src-pages-content-promoitems-js" */),
  "component---src-pages-content-tageditonly-js": () => import("./../../../src/pages/content/tageditonly.js" /* webpackChunkName: "component---src-pages-content-tageditonly-js" */),
  "component---src-pages-content-tagexclusive-js": () => import("./../../../src/pages/content/tagexclusive.js" /* webpackChunkName: "component---src-pages-content-tagexclusive-js" */),
  "component---src-pages-content-tagexclusivefiles-js": () => import("./../../../src/pages/content/tagexclusivefiles.js" /* webpackChunkName: "component---src-pages-content-tagexclusivefiles-js" */),
  "component---src-pages-content-tagimage-js": () => import("./../../../src/pages/content/tagimage.js" /* webpackChunkName: "component---src-pages-content-tagimage-js" */),
  "component---src-pages-franchise-applicants-js": () => import("./../../../src/pages/franchise/applicants.js" /* webpackChunkName: "component---src-pages-franchise-applicants-js" */),
  "component---src-pages-franchise-franchise-js": () => import("./../../../src/pages/franchise/franchise.js" /* webpackChunkName: "component---src-pages-franchise-franchise-js" */),
  "component---src-pages-franchise-franchisee-js": () => import("./../../../src/pages/franchise/franchisee.js" /* webpackChunkName: "component---src-pages-franchise-franchisee-js" */),
  "component---src-pages-franchise-notification-js": () => import("./../../../src/pages/franchise/notification.js" /* webpackChunkName: "component---src-pages-franchise-notification-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-listmanager-address-js": () => import("./../../../src/pages/listmanager/address.js" /* webpackChunkName: "component---src-pages-listmanager-address-js" */),
  "component---src-pages-listmanager-buyer-js": () => import("./../../../src/pages/listmanager/buyer.js" /* webpackChunkName: "component---src-pages-listmanager-buyer-js" */),
  "component---src-pages-listmanager-contactinfo-js": () => import("./../../../src/pages/listmanager/contactinfo.js" /* webpackChunkName: "component---src-pages-listmanager-contactinfo-js" */),
  "component---src-pages-listmanager-organization-js": () => import("./../../../src/pages/listmanager/organization.js" /* webpackChunkName: "component---src-pages-listmanager-organization-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-messaging-emailer-js": () => import("./../../../src/pages/messaging/emailer.js" /* webpackChunkName: "component---src-pages-messaging-emailer-js" */),
  "component---src-pages-messaging-messaging-js": () => import("./../../../src/pages/messaging/messaging.js" /* webpackChunkName: "component---src-pages-messaging-messaging-js" */),
  "component---src-pages-messaging-organizationnotification-js": () => import("./../../../src/pages/messaging/organizationnotification.js" /* webpackChunkName: "component---src-pages-messaging-organizationnotification-js" */),
  "component---src-pages-messaging-pickuppointnotification-js": () => import("./../../../src/pages/messaging/pickuppointnotification.js" /* webpackChunkName: "component---src-pages-messaging-pickuppointnotification-js" */),
  "component---src-pages-messaging-sms-js": () => import("./../../../src/pages/messaging/sms.js" /* webpackChunkName: "component---src-pages-messaging-sms-js" */),
  "component---src-pages-messaging-smsfull-js": () => import("./../../../src/pages/messaging/smsfull.js" /* webpackChunkName: "component---src-pages-messaging-smsfull-js" */),
  "component---src-pages-order-allorder-js": () => import("./../../../src/pages/order/allorder.js" /* webpackChunkName: "component---src-pages-order-allorder-js" */),
  "component---src-pages-order-confirmorder-js": () => import("./../../../src/pages/order/confirmorder.js" /* webpackChunkName: "component---src-pages-order-confirmorder-js" */),
  "component---src-pages-order-orderpayments-js": () => import("./../../../src/pages/order/orderpayments.js" /* webpackChunkName: "component---src-pages-order-orderpayments-js" */),
  "component---src-pages-order-overrideorder-js": () => import("./../../../src/pages/order/overrideorder.js" /* webpackChunkName: "component---src-pages-order-overrideorder-js" */),
  "component---src-pages-order-supplierorder-js": () => import("./../../../src/pages/order/supplierorder.js" /* webpackChunkName: "component---src-pages-order-supplierorder-js" */),
  "component---src-pages-order-viewsupplierorder-js": () => import("./../../../src/pages/order/viewsupplierorder.js" /* webpackChunkName: "component---src-pages-order-viewsupplierorder-js" */),
  "component---src-pages-package-busshippingschedule-js": () => import("./../../../src/pages/package/busshippingschedule.js" /* webpackChunkName: "component---src-pages-package-busshippingschedule-js" */),
  "component---src-pages-package-deliveryschedule-js": () => import("./../../../src/pages/package/deliveryschedule.js" /* webpackChunkName: "component---src-pages-package-deliveryschedule-js" */),
  "component---src-pages-package-package-js": () => import("./../../../src/pages/package/package.js" /* webpackChunkName: "component---src-pages-package-package-js" */),
  "component---src-pages-package-pickupschedule-js": () => import("./../../../src/pages/package/pickupschedule.js" /* webpackChunkName: "component---src-pages-package-pickupschedule-js" */),
  "component---src-pages-package-receive-js": () => import("./../../../src/pages/package/receive.js" /* webpackChunkName: "component---src-pages-package-receive-js" */),
  "component---src-pages-package-release-js": () => import("./../../../src/pages/package/release.js" /* webpackChunkName: "component---src-pages-package-release-js" */),
  "component---src-pages-package-tracking-js": () => import("./../../../src/pages/package/tracking.js" /* webpackChunkName: "component---src-pages-package-tracking-js" */),
  "component---src-pages-product-approve-js": () => import("./../../../src/pages/product/approve.js" /* webpackChunkName: "component---src-pages-product-approve-js" */),
  "component---src-pages-product-availability-js": () => import("./../../../src/pages/product/availability.js" /* webpackChunkName: "component---src-pages-product-availability-js" */),
  "component---src-pages-product-dimensions-js": () => import("./../../../src/pages/product/dimensions.js" /* webpackChunkName: "component---src-pages-product-dimensions-js" */),
  "component---src-pages-product-exclusivepricing-js": () => import("./../../../src/pages/product/exclusivepricing.js" /* webpackChunkName: "component---src-pages-product-exclusivepricing-js" */),
  "component---src-pages-product-images-js": () => import("./../../../src/pages/product/images.js" /* webpackChunkName: "component---src-pages-product-images-js" */),
  "component---src-pages-product-pricing-js": () => import("./../../../src/pages/product/pricing.js" /* webpackChunkName: "component---src-pages-product-pricing-js" */),
  "component---src-pages-product-pricingavailable-js": () => import("./../../../src/pages/product/pricingavailable.js" /* webpackChunkName: "component---src-pages-product-pricingavailable-js" */),
  "component---src-pages-product-product-js": () => import("./../../../src/pages/product/product.js" /* webpackChunkName: "component---src-pages-product-product-js" */),
  "component---src-pages-product-promoproduct-js": () => import("./../../../src/pages/product/promoproduct.js" /* webpackChunkName: "component---src-pages-product-promoproduct-js" */),
  "component---src-pages-product-regularproduct-js": () => import("./../../../src/pages/product/regularproduct.js" /* webpackChunkName: "component---src-pages-product-regularproduct-js" */),
  "component---src-pages-product-request-js": () => import("./../../../src/pages/product/request.js" /* webpackChunkName: "component---src-pages-product-request-js" */),
  "component---src-pages-product-requestpromo-js": () => import("./../../../src/pages/product/requestpromo.js" /* webpackChunkName: "component---src-pages-product-requestpromo-js" */),
  "component---src-pages-reports-customer-profile-js": () => import("./../../../src/pages/reports/customer/profile.js" /* webpackChunkName: "component---src-pages-reports-customer-profile-js" */),
  "component---src-pages-reports-customer-sales-js": () => import("./../../../src/pages/reports/customer/sales.js" /* webpackChunkName: "component---src-pages-reports-customer-sales-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-reports-product-franchisesale-js": () => import("./../../../src/pages/reports/product/franchisesale.js" /* webpackChunkName: "component---src-pages-reports-product-franchisesale-js" */),
  "component---src-pages-reports-product-sales-js": () => import("./../../../src/pages/reports/product/sales.js" /* webpackChunkName: "component---src-pages-reports-product-sales-js" */),
  "component---src-pages-reports-product-unserved-js": () => import("./../../../src/pages/reports/product/unserved.js" /* webpackChunkName: "component---src-pages-reports-product-unserved-js" */),
  "component---src-pages-scanqr-js": () => import("./../../../src/pages/scanqr.js" /* webpackChunkName: "component---src-pages-scanqr-js" */),
  "component---src-pages-seminars-notifications-js": () => import("./../../../src/pages/seminars/notifications.js" /* webpackChunkName: "component---src-pages-seminars-notifications-js" */),
  "component---src-pages-seminars-payments-js": () => import("./../../../src/pages/seminars/payments.js" /* webpackChunkName: "component---src-pages-seminars-payments-js" */),
  "component---src-pages-seminars-registrants-js": () => import("./../../../src/pages/seminars/registrants.js" /* webpackChunkName: "component---src-pages-seminars-registrants-js" */),
  "component---src-pages-seminars-seminar-js": () => import("./../../../src/pages/seminars/seminar.js" /* webpackChunkName: "component---src-pages-seminars-seminar-js" */),
  "component---src-pages-seminars-series-js": () => import("./../../../src/pages/seminars/series.js" /* webpackChunkName: "component---src-pages-seminars-series-js" */),
  "component---src-pages-user-userlogin-js": () => import("./../../../src/pages/user/userlogin.js" /* webpackChunkName: "component---src-pages-user-userlogin-js" */),
  "component---src-pages-user-usermenuset-js": () => import("./../../../src/pages/user/usermenuset.js" /* webpackChunkName: "component---src-pages-user-usermenuset-js" */),
  "component---src-pages-user-userpermissions-js": () => import("./../../../src/pages/user/userpermissions.js" /* webpackChunkName: "component---src-pages-user-userpermissions-js" */),
  "component---src-pages-user-usertypemenu-js": () => import("./../../../src/pages/user/usertypemenu.js" /* webpackChunkName: "component---src-pages-user-usertypemenu-js" */)
}

